<template>
  <section class="el-login-perfectInfo-router">
    <div class="main">
      <div class="title">请输入密码并完善资料</div>
      <el-form :model="form.data" :rules="form.rules" ref="form">
        <el-form-item>
          <el-input type="text" v-model="form.data.account" disabled placeholder="请输入用户名" clearable>
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="old_password">
          <el-input type="old_password" show-password v-model="form.data.old_password" placeholder="请输入旧密码" clearable>
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="newpassword1">
          <el-input
            type="newpassword1"
            show-password
            v-model="form.data.newpassword1"
            placeholder="请输入新密码（密码必须大于5位）"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-lock2"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="newpassword2">
          <el-input
            type="newpassword2"
            show-password
            v-model="form.data.newpassword2"
            placeholder="确认新密码（两次输入必须一致）"
            clearable
          >
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-lock2"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input type="phone" v-model="form.data.phone" placeholder="请输入手机号" clearable>
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-phone"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="realname">
          <el-input type="realname" v-model="form.data.realname" placeholder="请输入姓名" clearable>
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-username"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="department">
          <el-input type="department" v-model="form.data.department" placeholder="请输入部门名称" clearable>
            <i slot="prefix" class="el-input__icon el-input__icon2 icon icon-department"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')" v-loading="loading">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </section>
</template>

<script>
import Login from '@/api/login';
import { initState } from '@/store';
export default {
  // 完善资料
  name: 'perfectInfo',
  props: {},
  components: {},
  data() {
    let phone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!/^1[34578]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    let newpassword2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.data.newpassword1) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        data: {
          account: '',
          old_password: '',
          newpassword1: '',
          newpassword2: '',
          phone: '',
          realname: '',
          department: '',
        },
        rules: {
          old_password: [{ required: true, message: '请输入旧密码', trigger: 'change' }],
          newpassword1: [{ required: true, message: '请输入新密码', trigger: 'change' }],
          newpassword2: [{ validator: newpassword2, trigger: 'blur' }],
          phone: [{ validator: phone, trigger: 'blur' }],
          realname: [{ required: true, message: '请输入姓名', trigger: 'change' }],
          department: [{ required: true, message: '请输入部门名称', trigger: 'change' }],
        },
      },
      loading: false,
    };
  },
  methods: {
    submitForm(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let form = JSON.parse(JSON.stringify(this.form.data));
          form.password = form.newpassword2;
          delete form.newpassword2;
          delete form.newpassword1;
          delete form.account;
          Login.perfectInfo(form)
            .then(() => {
              this.$store.commit('init', initState);
              this.$myremove();
              this.$emit('switchComponent', 'login');
              window.removeEventListener('keydown', this.enter);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    enter(e) {
      if (e.key === 'Enter') {
        return this.submitForm('form');
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.enter);
  },
  created() {
    let { user } = this.$store.getters;
    this.form.data.account = user.username;
  },
  computed: {},
  watch: {},
  beforeDestroy() {
    window.removeEventListener('keydown', this.enter);
  },
};
</script>

<style scoped lang="scss">
.el-login-perfectInfo-router {
  background: repeating-linear-gradient(90deg, #4859fe, #0acefe);
  display: flex;
  height: 100vh;
  .main {
    margin: auto;
    background-color: #fff;
    border-radius: 20px;
    text-align: center;
    width: 420px;
    height: 580px;
    padding: 23px 40px 0 40px;
    .title {
      color: rgb(0, 121, 254);
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 22px;
    }
    ::v-deep .el-input__inner {
      height: 42px;
      line-height: 42px;
      text-indent: 10px;
    }
    .el-input__icon2 {
      height: 42px;
      line-height: 42px;
      font-size: 20px;
      padding-left: 5px;
    }
    .el-button {
      width: 100%;
    }
  }
}
</style>
